body {
  margin: 0;
  font-family:  "SF Pro Text", "Helvetica Neue","Helvetica","Arial","sans-serif"; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9rem;
  color: #1d1d1f;
  background-color: #fafafc;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  --b100: #000;
    --b80: #333;
    --b70: #555;
    --b60: #777;
    --b50: #999;
    --b45: #aaa;
    --b40: #bbb;
    --b30: #e6e6e8;
    --b20: #ececf1;
    --b15: #f2f2f4;
    --b10: #fafafc;
    --b0: #fff;
    --blue50: #f4fbff;
    --blue100: #e5f1ff;
    --blue200: #cbdcff;
    --blue300: #afc1ff;
    --blue500: #7989ff;
    --blue500: #3c46ff;
    --blue600: #00f;
    --blue700: #0404ac;
    --blue800: #020267;
    --blue900: #00002e;
    --blue: var(--blue600);
    --success: #0ccf7b;
    --warning: #d9a22b;
    --danger: #ff3374;
    --text-default: var(--b60);
    --text-disabled: var(--b50);
    --page-background-color: var(--b10);
    --input-border: var(--b20);
    --heading-margin-top: 30px;
    --heading-margin-bottom: 16px;
    --content-medium: 760px;
    --content-large: 1200px;
    --content-h-padding: 20px;
    --sans-serif: "ColfaxAI",Helvetica,sans-serif;
    --serif: Charter,Georgia,serif;
    --monospace: "Roboto Mono",SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
}

a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}

.row{
  margin: 0px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidescroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

