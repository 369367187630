.Recette {
  background-color: var(--b20);
  overflow: hidden;
  padding: 15px;
  top: 10rem;
  right: 20px;
  position: absolute;
  width: 340px;
  max-height: calc(100vh - 15rem);
  z-index: 0;
  border-radius: 8px;
  /* box-shadow: 0 4px 4px 0 rgb(0 0 82 / 15%); */
  box-shadow: 0 3px 3px 0 rgb(0 0 60 / 15%);

  opacity: 0.9;

}
.ReducedRecette{
  max-height: 45px;
}
.detailsRecette{
  overflow-y: auto;
  max-height: calc(100vh - 20rem);
}

.bold{
  font-weight: 700;
}
.ingredient {
  font-size: 14px;
  font-weight: normal;
  padding-left: 1em;
}
