.footer {
  margin-top: auto;
  /* display: none; */
}

@media all and (max-width: 375px) {
  .footer {
    display: none;
  }
}
