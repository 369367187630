.diagram-container {
  position: relative;
  top: 3.5rem;
  overflow: hidden;
  height: calc(100vh - 4em);
}

.menurecipe {
  top: 3.5rem;
  width: 100%;
  height: 30px;
}

.Button {
  width: 38px;
  border: solid 1px rgb(200, 200, 200);
  height: 38px;
}

.Zoom {
  width: 48%;
  border: solid 1px rgb(200, 200, 200);
  height: 38px;
  margin-left: auto;
  margin-right: auto;
}

input:focus,
button:focus {
  outline: 0;
}

.RecetteNeed {
  margin-top: 10px;
  width: 100%;
}

.css-b62m3t-container {
  width: 100%;
}

.Buttoncontainer {
  padding: 0;
  display: flex;
  margin: auto;
}
