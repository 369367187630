.libcontainer {
  position: relative;
  top: 3.5rem;
  .newlib {
    margin: 10px;
    cursor: pointer;
    width: 35px;
    height: 35px;

    :hover {
      box-shadow: 4px 4px 16px rgb(0 0 0 / 20%);
      transform: scale3d(1.01, 1.01, 1.01);
    }
  }
}
.card-body {
  .card-header {
  }

  .card-section {
    .card-section-header {
      font-size: medium;
      padding: 5px;
    }
    .card-section-content {
      background-color: white;
      font-size: small;
      padding: 5px;
      min-height: 100px;
    }
  }
  .card-ops {
    display: flex;
    margin: 1rem;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 1.2rem;
      cursor: pointer;

      :hover {
        box-shadow: 4px 4px 16px rgb(0 0 0 / 20%);
        // transform: scale3d(1.01, 1.01, 1.01);
      }
    }
  }
}
