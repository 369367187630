.home{
    display: flex;
    overflow: auto;
    margin-top: 4rem;

  }

.content{
    /* margin-left: 250px; */
}

.Sidbarhider{
    position: fixed;
    height: 3rem;
    width: 3rem;
    top: 3.5rem;
    left: 0rem;
    /* margin-left: 1rem; */
    cursor: pointer;
    fill: black !important;
    align-items: center;
    display: inline-flex;
    z-index: 1032;
  }

  .Sidbarhider svg{
    height: 20px;
    width: 20px;
    margin-left: auto;
    margin-right: auto;
  }