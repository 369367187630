



@font-face {
	font-family:'SF Pro Text';
	font-style:normal;
	font-weight:300;
	src:local('SF-Pro-Text'), url("./assets/fonts/SF-Pro-Text/sf-pro-text-light.woff2") format("woff2"), url("./assets/fonts/SF-Pro-Text/sf-pro-text-light.woff") format("woff"), url("./assets/fonts/SF-Pro-Text/sf-pro-text-light.ttf") format("truetype");
}



@font-face {
	font-family:'SF Pro Text';
	font-style:normal;
	font-weight:500;
	src:local('SF-Pro-Text'), url("./assets/fonts/SF-Pro-Text/sf-pro-text-medium.woff2") format("woff2"), url("./assets/fonts/SF-Pro-Text/sf-pro-text-medium.woff") format("woff"), url("./assets/fonts/SF-Pro-Text/sf-pro-text-medium.ttf") format("truetype");
}



@font-face {
	font-family:'SF Pro Text';
	font-style:normal;
	font-weight:600;
	src:local('SF-Pro-Text'), url("./assets/fonts/SF-Pro-Text/sf-pro-text-semibold.woff2") format("woff2"), url("./assets/fonts/SF-Pro-Text/sf-pro-text-semibold.woff") format("woff"), url("./assets/fonts/SF-Pro-Text/sf-pro-text-semibold.ttf") format("truetype");
}


@font-face {
	font-family:'SF Pro Text';
	font-style:normal;
	font-weight:700;
	src:local('SF-Pro-Text'), url("./assets/fonts/SF-Pro-Text/sf-pro-text-bold.woff2") format("woff2"), url("./assets/fonts/SF-Pro-Text/sf-pro-text-bold.woff") format("woff"), url("./assets/fonts/SF-Pro-Text/sf-pro-text-bold.ttf") format("truetype");
}


@font-face {
	font-family:'SF Pro Text';
	font-style:normal;
	font-weight:800;
	src:local('SF-Pro-Text'), url("./assets/fonts/SF-Pro-Text/sf-pro-text-heavy.woff2") format("woff2"), url("./assets/fonts/SF-Pro-Text/sf-pro-text-heavy.woff") format("woff"), url("./assets/fonts/SF-Pro-Text/sf-pro-text-heavy.ttf") format("truetype");
}
