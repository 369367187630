section{
    min-height: 100vh;
    /* min-width: 100vw; */
    width: 100%;
    padding-top: 3.5rem;
  }
  
  section > div{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  
  }

  .container-fluid{
      padding-left: 0px;
      padding-right: 0px;
      /* mask-image: linear-gradient(to right, rgba(0, 0, 0, 1.0), transparent 90%); */
  }