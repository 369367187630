.modal {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  color: white;
}

.modal-main {
  position: fixed;
  min-height: 3.5rem;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 980px;
}

.display-block {
  display: block;
}
.icon-cog {
  color: black;
}

.display-none {
  display: none;
}

#sbar {
  display: inline-flex !important;
  margin-left: auto;
}

#stbar {
  background: rgba(0, 0, 0, 0.8);
  border: 0;
}

.searchform-input {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.29412;
  letter-spacing: -0.021em;
  font-family: "SF Pro Text", "Myriad Set Pro", "SF Pro Icons", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  color: #fff;
  outline: none;
  padding: 0 28px 0 30px;
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
  min-width: 580px;
}

.searchform-shortcuts {
  top: 3.5rem;
  position: relative;
  background: white;
  width: 600px;
  max-height: 255px;
  border-top: 1px solid #d2d2d7;
  padding: 10px 0 18px;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden  ;
}

.animated {
  animation-delay: 0.2s;
}

.searchform-shortcuts-content {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0;
  color: #6e6e73;
}

.searchresults-shortcuts-list {
  list-style: none;
  padding-top: 5px;
  padding-inline-start: 0;
}

.searchresults-shortcuts-link {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #1d1d1f;
  display: block;
  padding: 7px 3rem;
  text-decoration: none;
  cursor: pointer;
}
.searchresults-shortcuts-link:hover {
  background-color: rgba(128, 128, 128, 0.05);
}

.searchresults-shortcuts-link > a:link,
.searchresults-shortcuts-link > a:hover {
  text-decoration: none!important;
}

.searchform-shortcuts-header{
  text-transform: capitalize;
  padding-left: 2rem;
  font-size: large;
}

.searchform-input:focus {
  color: #fff;
  outline: none;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

