  .custom-node {
    border: solid 3px rgb(220, 220, 220, 0.7);
    min-width: 120px;
    height: 80px;
    justify-content: space-between;
    position: relative;
    min-width: 170px;
    border-radius: 6px;
  }
  .node {
    box-shadow: 0 3px 3px 0 rgb(0 0 60 / 15%);
    border-radius: 6px;
  }

  .custom-node-extended {   
     height:130px
  }
  .custom-node:focus,
  .custom-node:hover {
    border: solid 3px rgb(0, 192, 255);
    /* animation: spin 10s linear infinite; */
  }
  
  .custom-node-bottom {
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
    height: 21px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  .custom-node-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    opacity: 0.8;
  }

 
  .css-b62m3t-container {
    width: 100%;
  }
  
  .Buttonconatiner {
    padding: 0;
    display: flex;
    margin: auto;
  }
  
  
  .default-port {
    background: rgb(220, 220, 220);
    cursor: pointer;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    height: 30px;
    color: #6b6b6b;
    font-size: 12px;
    width: 30px;
    opacity: 0.7;
  }
  
  .port {
    position: absolute;
    bottom: 0;
    /* height: 100%; */
  }
  
  .left-port {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 8px;

  }
  
  .right-0{
    right: 0;
  }
  .right-port {
    /* border-bottom-right-radius: 8px; */
    border-top-left-radius: 8px;
  }
  
  .default-port:hover {
    background: rgb(0, 192, 255);
    opacity: 1;
  }
  


  .abs {
    width: 100%;
    height: 50px;
    display: table;
    position: absolute;
  }

 

  .nodeName {
    text-align: center;
    color: white;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    max-width: 300px;
    padding: 10px;
  }
  
.close{
    border-top-right-radius: 2px;
    border-bottom-left-radius: 2px;
    width:22px;
    height:22px;
    display: flex;
    cursor: pointer;
    
}