.Login {
  max-width: 320px;
  margin-top: 10%;
}

@media all and (min-width: 576px) {
  .Login {
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .Login {
    margin-top: 10%;
  }
}

.error {
  color: #ba3939;
  background: #ffe0e0;
  /* border: 1px solid #a33a3a; */
  padding: 10px;
}
